<template>
  <div>
    <!-- 分享引导 -->
		<div v-if="isShow" class="yinDaoBox" @click="bindYindaoShareOut"
			style="width: 100vw; height: 100vh; z-index: 9999999;background: rgba(0,0,0,0.9);">
			<img src="https://img.alicdn.com/imgextra/i2/3655338353/O1CN01HRxsQF2BZizlhVzuF_!!3655338353.gif"
				style="width: 15%;position: absolute;right: 2vw;top: 1vh;" />
			<div style="position: absolute;text-align: right;right: 5vw;top: 15vh;font-size: 0.5rem;color: #ffffff;">
				点击 ··· 发送给朋友
			</div>
		</div>
  </div>
</template>

<script>
import util from '../../../utils/utils';
export default {
  props:{
    'show':{
      type:Boolean,
      default:false
    },
  },
  components:{
    
  },
  data(){
    return {
      isShow:this.show
    }
  },
  watch:{
    show:{
      handler(newVal,oldVal){
        this.isShow=newVal;
        //如果不是微信就提示
        // if(newVal&&!util.isWechat()){
        //   this.isShow=false;
        //   try{
        //     this.$message('请前往微信浏览器分享')
        //   } catch(err){}
        //   this.bindYindaoShareOut();
        // }
      },
      deep:true,//深度监听
      immediate:false //
    },
  },
  mounted(){
   
  },
  methods:{
    
    bindYindaoShareOut() {
      this.isShow = false;
      this.$emit("bindclose");
    },
  }
}
</script>

<style scoped>
  div{
    box-sizing: border-box;
  }
  .yinDaoBox{
    height: 100vh;
    max-width: 420px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin:auto;
    z-index:9999

  }
</style>