import Vue from 'vue'
import App from './index.vue'
import router from '../../router'
// import store from '@/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false

import wechat from '../../../utils/wxConfig.js';
Vue.prototype.$wx = wechat;

// document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
setTimeout(()=>{
  let el=document.querySelector('.cyContainer');
  console.log("屏幕宽度：",el.offsetWidth);
  document.documentElement.style.fontSize = el.offsetWidth / 750 * 100 + 'px';
},500)

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')