<template>
    <div class="loadingBox">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
   .loadingBox{
    min-width:80px;
    display: inline-block;
    min-height:45px;
    display: flex;
    align-items: center;
  }
  .loadingBox .dot{
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 100%;
    background: #909ba5;
  }
  .dot.dot1{
    animation: dot_cartoon 0.6s linear  infinite alternate both;
  }
  .dot.dot2{
    animation: dot_cartoon 0.6s linear .3s infinite alternate both;
  }
  .dot.dot3{
    animation: dot_cartoon 0.6s linear 0.6s infinite alternate both;
  }
  @keyframes dot_cartoon {
    0%{
      transform: scale(0.3);
    }
    60%{
      transform: scale(0.4);
    }
    100%{
      transform: scale(0.8);
    }
  }
</style>