<template>
  <div v-if="isShow" class="showToastBox">
    <div class="txt">{{t_title}}</div>
  </div>
</template>

<script>
import util from '../../../utils/utils';
export default {
  props:{
    'show':{
      type:Boolean,
      default:false
    },
    'title':{
      type:String,
      default:"加载中..."
    },
  },
  components:{
    
  },
  data(){
    return {
      isShow:this.show,
      t_title:this.title||"加载中..."
    }
  },
  watch:{
    show:{
      handler(newVal,oldVal){
        this.isShow=newVal;
        if(newVal){
          try{
            clearTimeout(this.timer);
          } catch(err){}
          this.timer=setTimeout(()=>{
            this.isShow=false;
            this.$emit('close');
          },1500)
        }
      },
      immediate:false //
    },
    title:{
      handler(newVal,oldVal){
        if(newVal){
          this.t_title=newVal;
        }
      },
      immediate:false //
    },
  },
  mounted(){
   
  },
  methods:{
    
    
  }
}
</script>

<style scoped>
  div{
    box-sizing: border-box;
  }
  .showToastBox{
    width: 100vw;
    height: 100vh; 
    position: fixed;
    left: 0;
    top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  .txt{
    display: inline-block;
    background: rgba(0,0,0,0.6);
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    font-size: 0.3rem;
  }
</style>