<template>
  <div class="container">
    <div v-if="empowerShow" class="shade" @tap="close"></div>
    <div class="box" :class="empowerShow?'show':'hide'">
      <div class="box-title">
        <img
          style="width: 60px; height: auto; margin-right: 20px"
          :src="applet_avatar"
        />
        {{ applet_name }} 申请
      </div>
      <div style="font-size: 38px; font-weight: bold; margin: 40px 0 50px 0">获取您的昵称、头像</div>

      <div style="margin: 60px 0">
        <button
          class="btnStyle"
          open-type="chooseAvatar"
          @chooseavatar="onChooseAvatar"
        >
          <img class="avatar" :src="avatarUrl" />
        </button>
        <div title="昵称">
          <input
            type="nickname"
            class="input"
            placeholder="请输入昵称"
            @input="bindinput"
            @blur="bindblur"
          />
        </div>
        <div style="display:flex;justify-content:space-between">
          <button class="button" style="width: 45%" @click="close">
            取消
          </button>
          <button class="button" type="primary" style="width: 300px" @click="submit">
            确定
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import config from "../../../config";
import request from "../../../api/request";
export default {
   props:{
    'empower_show':{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      empowerShow:this.empower_show,
      avatarUrl: "@/assets/user.png",
      nickname: "",
      applet_avatar: config.touxiang,
      applet_name: config.appletName,
    };
  },
  watch: {
    empower_show:{
      handler(newVal,oldVal){
        this.empowerShow=newVal;
      },
      deep:true,//深度监听
      immediate:false //
    },
  },
  methods: {
    onChooseAvatar(e) {
      const { avatarUrl } = e.detail;
      this.isUpAvatar=true;
      this.avatarUrl = avatarUrl;
    },
    bindinput(e){
      this.nickname=e.detail.value
    },
    bindblur(e){
      this.nickname=e.detail.value
    },
    submit() {
      const self=this;
      if(!this.isUpAvatar){
        this.$message({
          showClose: true,
          message: '请选择头像！',
          duration:2000
        });
        return
      }
      if(!this.nickname){
        this.$message({
          showClose: true,
          message: '请输入昵称',
          duration:2000
        });
        return
      }
      this.$emit("close")
      this.empowerShow=false;
      //获取图片信息
      // wx.getImageInfo({
      //   src: this.avatarUrl,
      //   success: function (sres) {
          //获取到的头像进行上传
          uni.showLoading({
            title: '正在提交...'
          });
          request.upAvatarName({nickName:self.nickname},'file',this.avatarUrl).then(res=>{
            this.$emit("submit",res)
            uni.hideLoading();
          }).catch(err=>{
            this.$emit("submit")
            uni.hideLoading();
          });
        // },
        // fail: function (srev) {
        //   console.log(srev);
        // },
      // });
      
    },
    close(){
      this.empowerShow=false;
      this.$emit("close")
    }
  },
};
</script>

<style scoped>
.box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 300px;
  background: #fff;
  z-index: 999;
  border-radius: 30px 30px 0 0;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: -4px 0 10px #797979;
  transition: all .3s linear;
}
.show{
  bottom: 0;
}
.hide{
  bottom: -100%;
}
.box-title {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.avatar {
  display: block;
  width: 160px;
  height: 160px;
  cursor: pointer;
}
.input {
  height: 100px;
  /* border-top: 1px solid #e6e6e6; */
  border-bottom: 1px solid #e6e6e6;
  margin: 60px;
  margin-bottom: 100px;
}
.shade {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.button{
  display: inline-block;
}
</style>